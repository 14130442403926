<template>
  <ul  class="UlMenuBox">
    <!-- :class="[activeIndex==0?'active':'']" -->
    <li>
        <el-popover
          v-model="visible"
          popper-class="myPopover"
          ref="meun_list"
          trigger="hover"
          placement="bottom"
          width="210"
          :visible-arrow="false"
        >
          <leftNav v-show="renderFlag" @DivhideFun="hideFun"></leftNav>
        </el-popover>
        <span v-popover:meun_list>商品分类</span>
      </li>
      <!-- {{activeIndex  }}     {{ index }} -->
      <li @click="jumpStore" :class="[activeIndex==this.index?'active':'']">品牌店铺</li>
      <!-- .slice(0,arr.length-1) -->
    <li v-for="(item,index) in arr" :key="index" :class="[activeIndex==index+1?'active':'']"  @click="linkproduct(item,index)">{{item.name}}</li>
  </ul>
</template>
<script>
import {selectTagList}  from "api/home.js";
const leftNav = () => import("components/index/leftNav.vue");
export default {
  data() {
    return {
      arr:[],
      activeIndex: "0",
      visible: false,
      renderFlag: "false",
      index:0,
    };
  },
  components: {
    leftNav,
  },
  methods: {
    hideFun() {
      this.visible = false;
    },
    jumpStore(){
      this.activeIndex=0
      this.index=0
      this.$router.push({name:'ShopList',query:{type:0}})
    },
      // 除首页外的跳转
      jumpPath(item){
          console.log(item)
        // 跳标签列表页
        this.$router.push({name:"TagIdList",query:{tagId:item.tagId,type:item.num}})
      },
    linkproduct(item,index){
      console.log(index)
      console.log(this.activeIndex)
      if(item.num ===0){
        this.$router.push({name:item.path})
      }else{
        console.log('8888888',item)
        if(item.path=="IntegralMallList"){
          this.$router.push({name:item.path,query:{type:item.num}})
        }else{
            this.jumpPath(item)
        }
        if(sessionStorage.getItem("parmesData")){
          sessionStorage.removeItem("parmesData")
        }
      }
      
    },
    activeIndexFun(){
      let activeindex="0"
      if(this.$route.query.type){
        activeindex=String(Number(this.$route.query.type))
      }else{
        activeindex="-1"
      }
      this.activeIndex=activeindex
      console.log('this.activeIndex',this.activeIndex)
    }
  },

  watch: {  
    // 使用 watch 监听路由参数的变化，这通常在组件已经创建之后  
    '$route.query.type': function(toVal) {  
      this.activeIndex = String(Number(toVal) || 0); // 根据需要设置默认值  
    }  
  },  
  beforeRouteUpdate(to, from, next) {  
    // 当路由参数变化，但组件复用时，这个守卫会被调用  
    this.activeIndex = String(Number(to.query.type) || -1); // 更新 activeIndex  
    next();  
  },  
  computed: {
    
  },
  created(){
    selectTagList().then(
        res=>{
          this.arr=[];
          let menuArr=res.data.data;
           console.log('menuArr',menuArr)
          // this.arr.push({name:"首页",path:"Home",num:0})
          menuArr.forEach((item,i)=> {
            this.arr.push({name:item.tagName,path:"tagIdList",num:i+1,tagId:item.id})
          });

          // this.arr.push(  {name:"积分商城",path:"IntegralMallList",num:this.arr.length+1})
          
          console.log('reeeeee',this.arr)
          this.activeIndexFun()

        }
      )
  },

  
};
</script>
<style>

.myPopover {
  padding: 0 !important;
  border-radius: 0;
  border: none;
  box-shadow: none;
  margin-top: 10px !important;
}
</style>
<style scoped lang="less">
.UlMenuBox{
  li{
    float: left;
    color: #333333;
    height: 40px;
    line-height: 40px;
    padding:0 8px;
    font-size: 14px;
    &:hover{
      border-bottom: 2px solid #ff3333;
      color: #ff3333;
      border-bottom: none;
      background: #f6f6f6;
    }
    &.active {
      border-bottom: 2px solid #ff3333;
      color: #ff3333;
    }
  }
}
// .el-menu a {
//   text-decoration: none;
// }
// .el-menu--horizontal > .el-menu-item:hover{
//   border-bottom: 2px solid #ff3333;
//   color: #ff3333;
// }
// .el-menu--horizontal > .el-menu-item.is-active {
//   border-bottom: 2px solid #ff3333;
//   color: #ff3333;
// }
// .el-menu--horizontal > .el-menu-item {
//   color: #333333;
//   height: 40px;
//   line-height: 40px;
//   padding:0 8px;
//   font-size: 14px;
// }
// .el-menu.el-menu--horizontal {
//   border-bottom: none;
//   background: #f6f6f6;
// }
</style>
