<template>
  <div id="app">
    <Header></Header>
    <el-main>
      <router-view  v-if="isRouterAlive" :key="$route.path + $route.query.ids" />
    </el-main>
    <el-footer>
      <Footer></Footer>
    </el-footer>
    <BackTop  @clickHeader='clickHeader'></BackTop>
  </div>
</template>
<style lang="less">
/*elementui loading css 覆盖 开始*/
 .el-loading-spinner .circular{
  width: 42px;
  height: 42px;
  animation: loading-rotate 2s linear infinite;
  display: none;
}
.el-loading-spinner{
  background: url('../src/assets/loadingCircles.gif') no-repeat;
  background-size: 48px 48px;
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  left: 45%;
}

</style>
<style lang="less" scoped>
@import "~style/index.less";
#app {
  background: #fff;
  padding-top:93px;
  .router-link-active {
    font-weight: 900;
  }
  .el-header {
    margin: 0;
    padding: 0;
    background: #fff;
  }
  .el-main {
    // width: 1200px;
    // margin: 0 auto;
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    // padding-bottom: 0px !important;
  }
  
}

.el-footer {
  padding: 0px;
  background: #fff;
}
</style>

<script>
const Header = () => import("components/common/Header.vue");
const Footer = () => import("components/common/Footer.vue");
const BackTop = () => import("components/index/BackTop.vue");
export default {
   provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
            return {
                reload: this.reload
            }
        },
   data() {
    return {
      name:'',
      isRouterAlive: true
    }
   },
  components: {
    Header,
    Footer,
    BackTop,
  },
  methods: {
     reload () {
          this.isRouterAlive = false;            //先关闭，
          this.$nextTick(function () {
              this.isRouterAlive = true;         //再打开
          }) 
      },
      clickHeader(){
        console.log(this.$refs.HeadRef)
        this.$refs.HeadRef.SearchShowFun()
      }
  },
  watch: {
    "$route.path": function(newVal) {


      if( newVal == "/topicPage"){
        document.querySelector("#app").style.paddingTop= "128px";
        document.querySelector(".el-main").style.width= "1200px";
        document.querySelector(".el-main").style.margin= "0 auto";
      }else{
        document.querySelector("#app").style.paddingTop= "93px";
        document.querySelector(".el-main").style.width= "100%";
      }


      if (
         newVal == "/cart" ||
          newVal == "/freeMailDetail" ||
        newVal == "/integralMallDetail" ||
         newVal == "/flashGroupDetail" ||
        newVal == "/activityModuleDetail" ||
        newVal == "/setMealDetail" ||
        newVal == "/comboDetail" ||
        //  newVal == "/oneBuyDetail" ||
        newVal == "/productdetail" ||
        newVal == "/amount" ||
        newVal == "/ordersumbit" 
        // ||
        // newVal == "/getCoupon"
      ) {
        document.querySelector("#app").style.backgroundColor = "#fff";
        // document.querySelector("#app").style.filter = "grayscale(0%)"
      } else if (newVal == "/login") {
        document.querySelector("#app").style.background = "#fff"
        // document.querySelector("#app").style.filter = "grayscale(0%)"
          // "radial-gradient(closest-side at 29% 49%, #FF6700 ,#FD2927)";
      }
      // else if (newVal == "/home") {
      //   // 哀悼日  首页灰白
      //   document.querySelector("#app").style.filter = "grayscale(100%)"
      // }
       else {
        document.querySelector("#app").style.background = "#f6f6f6";
        // document.querySelector("#app").style.filter = "grayscale(0%)"
      }
    },
  },
created() {
    function IEVersion() {
            var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
            var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
            var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
            var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
            if(isIE) {
                var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
                reIE.test(userAgent);
                var fIEVersion = parseFloat(RegExp["$1"]);
                if(fIEVersion == 7) {
                    return 7;
                } else if(fIEVersion == 8) {
                    return 8;
                } else if(fIEVersion == 9) {
                    return 9;
                } else if(fIEVersion == 10) {
                    return 10;
                } else {
                    return 6;//IE版本<=7
                }   
            } else if(isEdge) {
                return 'edge';//edge
            } else if(isIE11) {
                return 11; //IE11  
            }else{
                return -1;//不是ie浏览器
            }
        }
  
        if(IEVersion()!=-1 && IEVersion()!='edge'){
          // <a url="">IE Edge</a>  
            //  alert('IE版本太低，由于存在安全风险，已被本站禁止，请升级到IE Edge或使用Chrome浏览器。')

             this.$confirm(
              "IE版本太低,推荐使用Chrome浏览器",
              "由于存在安全风险，已被本站禁止，请升级到Edge或使用Chrome浏览器。",
              {
                confirmButtonText: "下载Chrome浏览器",
                cancelButtonText: "下载IE Edge",
                type: "warning",
              }
            )
              .then(() => {
                // 下载Chrome浏览器
                window.location.href="https://dl.google.com/tag/s/appguid%3D%7B8A69D345-D564-463C-AFF1-A69D9E530F96%7D%26iid%3D%7B5AF15316-7439-C7CD-8BA9-F16FC8559F6D%7D%26lang%3Dzh-CN%26browser%3D4%26usagestats%3D1%26appname%3DGoogle%2520Chrome%26needsadmin%3Dprefers%26ap%3Dx64-stable-statsdef_1%26installdataindex%3Dempty/update2/installers/ChromeSetup.exe"
              })
              .catch(() => {
                 // 跳转IE Edge
                window.location.href="https://microsoftedgewelcome.microsoft.com/launch?url=https%3A%2F%2Faka.ms%2FMicrosoftSupport1"
              });
        }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://s4.cnzz.com/z_stat.php?id=1279849782&web_id=1279849782'
    script.language = 'JavaScript'
    document.body.appendChild(script)
  },

  updated() {},
};
</script>
