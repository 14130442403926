<template>
  <!-- 登录后头部 -->
  <el-row class="headerBox"  >
    <div v-if="!flag" @click="hoverFun">
     

      <!-- <div class="header">
        <div class="header_top_box">
          <el-row :gutter="24" class="header_top">
            <el-col :span="10">
              <div class="header_left">
                <span><i class="el-icon-user"></i> 您好,</span>
                <span class="text-overFlow">{{ msg }}</span>
                <span  v-show="BtnShow" class="SwitchBtn" @click="switchAccountFun">切换门店 <i class="el-icon-arrow-down"></i></span>
                <span @click="jumpLogin" class="effectBtn">退出</span>
              </div>
            </el-col>
            <el-col :span="14" class="header_right">
              <div>
                <span class="text-center GspBox"  @click="jumpToNewProject" >药品GSP图片管理系统</span>
                <el-divider direction="vertical"></el-divider>
                <span @click="jumpMy"  class="effectBtn">个人中心</span>
                <el-divider direction="vertical"></el-divider>
                <span @click="jumpMyorder"  class="effectBtn">我的订单</span>
                <el-divider direction="vertical"></el-divider>
                <span>
                  <el-popover
                    trigger="hover"
                    placement="bottom"
                   class="QRCodeBox"
                    ref="QR_code"
                    :visible-arrow="false"
                  >
                    <img :src="srcList" alt=""  style="width:auto; height:300px">
                  </el-popover>
                  <span v-popover:QR_code  class="effectBtn">手机商城</span>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div> -->
      <div :class="searchFlag?'header_middle':'header_middle leftBox'">
       
        <el-col class="header_middle_left">
          <router-link to="/home"
            ><img
              rount-link
              class="logo"
              alt="Vue logo"
              src="@/assets/ybw_logo.png"
          /></router-link>
        </el-col>
        <el-col class="header_bottom">
         
          <el-col class="header_bottom_middle">
            <meun></meun>
          </el-col>
        </el-col>


        <div class="searchBtn" v-show='SearchShow'  >
          <search  :isSearch="isSearch"  @searchFun="searchFun"  @clearInpFun='clearInpFun'></search>
        </div>
        <el-col class="header_middle_center">
            <div class="search" @click='SearchShowFun'>
                <i class="el-icon-search"></i> 搜索
            </div>
            <div class="BuyBtn"   @click="jumpCart">
              <span><i class="el-icon-shopping-cart-2"></i></span>
                购物车
              <span class="bageBox">{{num}}</span>
            </div>

            <div class="userMsg"  @mouseenter="NavLiBoxFlag=true"  @mouseleave="NavLiBoxFlag=false">
              <!-- <span :class="msg.length>12?'userMsgRight text-overFlow1':'userMsgRight'" @mouseenter="NavLiBoxFlag=true" >{{ msg}}</span> -->
              <span class="userMsgRight text-overFlow"   :title="msg"   @mouseenter="NavLiBoxFlag=true">{{ msg}}</span>
              <span class="userMsgLeft"><i class="el-icon-user"></i>您好,</span>
              <ul class="NavLiBox" v-if="NavLiBoxFlag"   @mouseleave="NavLiBoxFlag=false">
                  <li @click="jumpMy"  class="effectBtn">个人中心</li>
                  <li @click="jumpMyorder"  class="effectBtn">我的订单</li>
                  <li class="text-center GspBox"  @click="jumpToNewProject" >药品GSP图片管理系统</li>
                  <!--  <i class="el-icon-arrow-down"></i> -->
                  <li  v-show="BtnShow" class="effectBtn" @click="switchAccountFun">切换门店</li>
                  <li @click="jumpLogin" class="effectBtn">退出</li>
              </ul>
            </div>
            <p :class="NavLiBoxFlag==true?'AllName active':'AllName'" v-if="msg.length>12">
              <el-tag type="info">{{ msg}}</el-tag>
            </p>
        </el-col>
      </div>
      
    </div>
    <!-- 未登录头部 -->
    <div class="login_header" v-show="flag">
      <div class="login_headerbox">
        <el-col class="login_headerbox_left">
          <img class="logo" alt="Vue logo" src="@/assets/ybw_logo.png" />
        </el-col>
        <div class="login_headerbox_center">
          <span v-show="!isLheader">欢迎注册</span>
          <!-- <span v-show="isLheader">欢迎登陆</span> -->
        </div>
        <div class="login_headerbox_right" v-show="!isLheader">
          已有账户？ <span class="cursorBox" @click="jumpLogin">立即登录</span>
        </div>
        <div class="login_headerbox_rightBox" v-show="isLheader">
          <!-- <em class="el-icon-phone-outline"></em> -->
          <div>
            <p><em><img src="@/assets/kefu.png" alt="客服"></em>客服电话</p>
          </div>
          <p><span >400-1008189</span></p>
           
        </div>
      </div>
    </div>

          
  </el-row>
</template>
<!-- <style>

.myPopover {
  padding: 0 !important;
  border-radius: 0;
  border: none;
  box-shadow: none;
  margin-top: 10px !important;
}
</style> -->
<style scoped lang="less">
.AllName{
  position: absolute;
  top:-18px;
  right: 0;
  text-align: right;
  font-size: 12px;
  line-height: 34px;
  display: none;
  &.active{
    display: block;
  }
}
.searchHigh{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index:1000;
  width:60px;
  float: left;
  height: 38px;
  line-height: 38px;
  border-radius: 20px;
  font-weight:bold;
 background: red;
  cursor: pointer;
  i{
    color: #FFF;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: block;
    margin: 10px auto;
  }
}
.searchBtn{
  position: fixed;
  left: 0;
  top: 0;
  // width: calc( 100% - 300px );
  width: 100%;
  height:60px;
  padding:15px 0;
  background: #fff;
  z-index: 9999;
}
.SmartSourcingBtn{
  cursor: pointer;
    display: inline-block;
    background-color: #ff3333;
    border-color: #ff3333;
    border-radius: 0px;
    color: #fff;
    font-size: 14px;
    padding: 0 20px;
    line-height: 38px;
    margin-top: 20px;
    float: right;
}
 /deep/ .el-button{
      background: #f43c38;
      color: #fff!important;
  }
.cursorBox{
  cursor: pointer;
}
.el-col {
  padding: 0;
}
.GspBox{
  cursor: pointer;
  &:hover{
    color: #f33;
  }
}
// 未登录
.login_header {
  height:90px;
  .login_headerbox {
    width: 1200px;
    margin: 0 auto !important;
    height:90px;
    .login_headerbox_left {
      float: left;
      width: 210px;
      text-align: left;
      padding:21px 0;
      .logo {
        width: 100%;
        height: auto;
      }
    }
    .login_headerbox_center {
      float: left;
      padding-left: 20px !important;
      font-size: 20px;
      font-weight: bold;
      line-height:90px;
      color: #333333;
    }
    .login_headerbox_right {
      float: right;
      width: 215px;
      font-size:13px;
      color: #666666;
      text-align: right;
      margin-top:40px;
      span {
        color: #ff3333;
      }
    }
    .login_headerbox_rightBox{
      float: right;
      width:180px;
      font-size:13px;
      color: #666666;
      text-align: right;
      margin-top:24px;
      line-height: 20px;
      div{
        width: 100%;
        height: 20px;
        p{
          width:86px;
          float: right;
          font-size:14px;
        }
      }
      em{
         float: left;
         display: inline-block;
         width:18px;
         height:18px; 
         padding-right:4px;
         img{
           width: 100%;
           height: 100%;
         }
      }
      span {
         display: inline-block;
         height: 30px;
         font-size:20px;
          line-height: 30px;
          color: #666666;
      }
    }
  }
}

// 兼容1280屏幕
// @media screen and (max-width: 1280px) {
//   .login_headerbox {
//     width: 990px !important;
//   }
//   .header_top {
//     width: 990px !important;
//   }
//   .header_middle {
//     width: 990px !important;
//   }
//   .header_bottom {
//     width: 990px !important;
//   }
//   /deep/.el-menu-item {
//     padding: 0 10px !important;
//   }
// }

// 登录后
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.SwitchBtn{
  cursor: pointer;
  color: #ff3333;
  i{
    color: #000;
    font-weight: bold;
  }
  &:hover{
    color: #ff3333;
  }
}
.effectBtn{
  cursor: pointer;
  &:hover{
    color: #ff3333;
  }
}
.headerBox{
  position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    left: 0;
    z-index: 999;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1)
}
.header {
  background: #f6f6f6;
  .header_top_box {
    background: #f6f6f6;
    .header_top {
      // width: 1200px;
      width: 1440px;
      margin: 0 auto !important;
      .header_left {
        font-size: 12px;
        text-align: left;
        line-height: 34px;
        & > span {
          padding-left: 10px;
        }
        & > span:first-child,
        & > span:nth-child(2) {
          padding-left: 0px;
        }
        .quickRegister {
          color: #ff3333;
        }
      }
      .header_right {
        text-align: right;
        line-height: 34px;
        font-size: 12px;
        padding: 0 !important;
      }
    }
  }
}
.header_middle {
  width: 1440px;
  margin: 0 auto !important;
  position: relative;
  &.leftBox{
      .search {
        float: right;
      }
      .itemBox{
        display: none;
      
      }
      .header_middle_right {
        display: none;
      }
      
      .hot_search {
        text-align: center;
      }
    }
  .header_middle_left {
    width:140px;
    text-align: left;
    padding:28px 0px 28px 0;
    .logo {
      width:140px;
      height: auto;
    }
  }

  .header_middle_center {
    // width: calc(100% - 880px);
    width: calc(100% - 920px);
    position: absolute;
    right: 0;
    top: 10px;
    .el-badge{
      float: right;
    }
    .search {
      width:120px;
      float: left;
      padding:0  10px!important;
      margin:10px 20px 10px 10px;
      height: 38px;
      line-height: 38px;
      border-radius: 20px;
      background: #F0F0F0;
      font-weight: 14px;
      color: #9D9D9D;
      // font-weight: bold;
      cursor: pointer;
      i{
        color: #3D3D3D;
      }
    }
    .BuyBtn{
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        // background: #f43c38;
        // border: 1px solid #ff3333;
        // color: #fff;
        -webkit-appearance: none;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        // margin: 0;
        margin-top: 10px;
        transition: .1s;
        font-weight: 500;
        // padding:3px 14px;
        padding:3px 10px 0 3px;
        font-size:14px;
        border-radius: 4px;
        border-radius: 0;
        line-height: 30px;
        i{
            font-weight: bold;
            font-size:16px;
          }
        
        .bageBox{
            background: #f43c38;
            border: 1px solid #ff3333;
            color: #fff;
            border-radius: 50%;
            padding:0 3px;
            font-size: 12px;
        }
    }
   
     .userMsg {
        float: right;
        font-size: 14px;
        text-align: right;
        // line-height:54px;
        height:80px;
        position: relative;
        cursor: pointer;
        .userMsgLeft{
          float: right;
          line-height: 54px;
        }
        .userMsgRight{
          float: right;
          text-align: right;
          line-height: 54px;
        }
        .text-overFlow{
          max-width:190px;
          // max-width:210px;
          white-space:nowrap;
          text-overflow:ellipsis;
          overflow:hidden;
          // line-height:28px!important;
          // overflow:hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp:2;
          // -webkit-box-orient: vertical;
        }
     
        .NavLiBox{
          position: absolute;
          right: 0;
          top: 48px;
          border-top:2px solid #f33;
          // width: 100%;
          width:180px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
          background: #fff;
          padding: 10px 20px;
          z-index: 999;
          li{
            width: 100%;
            text-align: left;
            line-height: 45px;
          }
        }
        & > span {
          padding-left: 10px;
          i{
            font-weight: bold;
            font-size:16px;
          }
        }
        & > span:first-child,
        & > span:nth-child(2) {
          padding-left: 0px;
        }
       
      }
   
  }
  .header_middle_right {
    width: 215px;
  }
  .hot_search {
    padding-left:22px;
    text-align: left;

    span {
      font-size: 12px;
      margin-right: 8px;
      color: #999999;
      &:hover{
        cursor: pointer;
        font-weight: bold;
        color: #ff3333;
      }
       &.selectActive{
        font-weight: bold;
        color: #ff3333;
      }
    }
  }
}
.header_bottom {
  // margin: 0 auto !important;

  width:840px;
  padding:0 0 0  30px!important;
  // width:800px;
  // padding:0 0 0  30px!important;


  margin-top: 20px;
  .header_bottom_left {
    width:70px;
    padding: 0!important;
    height: 40px;
    line-height: 40px;
    font-size:14px;
    cursor: pointer;
    // background: #ff3333;
    // color: #fff;
    text-align: center;
  }
  .header_bottom_middle {
    width: calc(100% - 70px);
     cursor: pointer;
     padding: 0!important;
  }
}
/deep/.el-popover{
  padding: 0;
}
span.demonstration {
  text-align: center;
  line-height: 24px;
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
}
.el-image {
  width: 100%;
  display: block;
}
.el-badge {
  margin-top: 10px;
  font-size: 12px;
}
.el-badge__content {
  background-color: #ff3333 !important ;
}
.el-button {
  border: 1px solid #ff3333;
  color: #ff3333;
  font-size: 12px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>

<script>
import { GetMemberStore } from "api/member.js";
import { HotWords } from "api/product.js";
const search = () => import("components/index/search.vue");
const meun = () => import("components/index/meun.vue");
// const leftNav = () => import("components/index/leftNav.vue");
// import IosCode from "assets/3Aios_down.png";
// import AZCode from "assets/3Aaz_down.png";
import Code from "assets/QRcode.png";
import { CartCount } from "api/product.js";      
import { mapState, mapGetters,mapActions,mapMutations } from "vuex";
import Cookies from 'js-cookie';
export default {
  name: "Header",
  inject:['reload'],
  components: {
    search,
    meun,
    // leftNav,
  },
  data() {
    return {
      NavLiBoxFlag:false,
      BtnShow: true,
      msg: "",
      // visible: false,
      // renderFlag: "false",
       srcList: [Code],
      // srcList: [IosCode,AZCode],
      searchList:[],
      // searchVal:'',
      isSearch:false,
      SearchShow:false,
      searchFlag:'true',
      activeIndex: '1',
    };
  },
  computed: {
    //计算属性可实时更新购物车数量
    ...mapState({
      flag: (state) => state.flag,
      num: (state) => state.carGoodsNum,
      isLogin: (state) => state.isLogin,
      isLheader: (state) => state.isLheader,
    }),
     ...mapGetters([
            'memberNameFun',
        ])
  },
  //监听执行
  watch: {
    "$route.path": function(newVal) {
      this.NavLiBoxFlag=false
      if(newVal!="/cart"){
         this.isloadFun()
         this.searchFlag=true
      }else{
          this.searchFlag=false
      }
    },
  //  "$route.path": function(newVal) {
  //     if(newVal=='/home'){
  //       // 获取店铺信息
  //        this.msg = localStorage.getItem("memberName")
  //     }
  //   },
    "msg":function(){
      this.isloadFun()
    },
     "memberNameFun": function (newValue) {
        if(newValue) {
          this.msg = localStorage.getItem("memberName");    //赋值后可监听上面msg
          // this.memberName(localStorage.getItem("memberName"))
           GetMemberStore().then((res) => {
              if(res.data.data.length>1){
                    this.BtnShow=true
              }else{
                this.BtnShow=false
              }
          });
          const parmesData = {
                  //暂无
                  current: 1,
                  size:10
                };
          HotWords(parmesData).then(res=>{
            // console.log('sdghjkl;',res.data.data.records)
            this.searchList=res.data.data.records
          })
        }
    }
  },
  created() {

    if(this.$route.path!="/cart"){
      this.isloadFun()
        this.searchFlag=true
    }else{
        this.searchFlag=false
    }
    
  },
  mounted(){
     this.msg = localStorage.getItem("memberName");
      // 监听全局点击事件  
    document.addEventListener('click', this.handleClickOutside);  
  },
  beforeDestroy() {  
    // 组件销毁前移除事件监听器，避免内存泄漏  
    document.removeEventListener('click', this.handleClickOutside);  
  },  
  methods: {
     // 处理点击盒子内部的事件  
     handleBoxClick() {  
      // 如果需要处理盒子内部点击的逻辑，可以在这里添加  
    },  
    // 处理点击盒子外部的事件 
    handleClickOutside(event) {  
      // 检查每个元素是否存在  
      const searchElement = this.$el.querySelector('.search');  
      const searchBtnElement = this.$el.querySelector('.searchBtn');  
      const searchHighElement = this.$el.querySelector('.searchHigh');  
      
      // 如果点击的目标不是这些元素或它们的子元素  
      if (  
        !(searchElement && searchElement.contains(event.target)) &&  
        !(searchBtnElement && searchBtnElement.contains(event.target)) &&  
        !(searchHighElement &&  searchHighElement.contains(event.target))  
      ) {  
        this.SearchShow = false; // 隐藏盒子  
      }  
    }, 
    // handleClickOutside(event) {  
    //   // 检查点击事件的目标是否是盒子或其子元素  
    //   if (!this.$el.querySelector('.search').contains(event.target) && !this.$el.querySelector('.searchBtn').contains(event.target)&& !this.$el.querySelector('.searchHigh').contains(event.target)  ) {  
    //     this.SearchShow = false; // 如果不是，则隐藏盒子  
    //   }  
    // },  
    SearchShowFun(){
        this.SearchShow=true;
    },
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
    searchFun(isSearch){
        this.isSearch=isSearch
       
    },
    clearInpFun(){
      this.SearchShow=false;
    },
    hoverFun(){
      if(this.isSearch){
        this.isSearch=false
      }
    },
      //热词点击
    //   searchHandler(HotWords) {
    //    localStorage.setItem("searchVal", HotWords);
    //     this.$router.push({name:"ProductType",query:{type:11,seachVal:HotWords}})
    //       this.searchVal=localStorage.getItem("searchVal")

    // },
      switchAccountFun(){
       this.$router.push({ path: "/my/myInvoice" });
    },
    jumpToNewProject: function () {
        var memberName=localStorage.getItem("memberName");
        let refresh_token=localStorage.getItem("refresh_token")
        //expires:  cookie有效时间   path: cookie所在目录 设置path: '/projectName'指定项目名下'/projectName'使用
        // domain: cookie所在的域，默认为请求地址
        //  Cookies.set('memberName', memberName, { expires: 1000, path: '', domain: 'http://localhost:8080/' })
        let domainStr=document.domain.split('.').slice(-2).join('.')
       
        // let JumpUrl='http://gsp.'+domainStr+'/#/login'
        // let JumpUrl='http://'+window.location.hostname+':8003/#/login'
        if(domainStr=='3akx.cn'||domainStr=='3akx.com'){
            Cookies.set('memberName',memberName,{path:'/',domain:'3akx.cn'});
          Cookies.set('refresh_token',refresh_token,{path:'/',domain:'3akx.cn'});
          window.open("https://gsp.3akx.cn/");
        }else{
           Cookies.set('memberName',memberName);
            Cookies.set('refresh_token',refresh_token);
            let JumpUrl='http://'+window.location.hostname+':8003/#/login'
            window.open(JumpUrl); // 这个地址是你要跳转的项目url
        }
    },
    isloadFun() {
      if (!this.flag) {
        if(this.$route.path!="/cart"){
              //首次加载初始化购物车数量
              // this.getGoods();
              if(localStorage.getItem("token")){
                  CartCount().then(res=>{
                    if(res.data!=undefined){
                      let sumdata={
                        initsum:Number(res.data),
                      }
                      this.setCarGoodsNum(sumdata)
                    }else{
                      let sumdata={
                        initsum:0,
                      }
                      this.setCarGoodsNum(sumdata)
                    }
                    // if(res.data.records!=undefined){
                    //   let result=res.data.records
                    //   let sum = result.reduce(function(prev, cur) {
                    //       return cur.quantity + prev;
                    //   }, 0);
                    //   let sumdata={
                    //     initsum:sum,
                    //   }
                    //   this.setCarGoodsNum(sumdata)
                    // }else{
                    //   let sumdata={
                    //     initsum:0,
                    //   }
                    //   this.setCarGoodsNum(sumdata)
                    // }
                  })
              }
        }
      }
    },
    // hideFun() {
    //   this.visible = false;
    // },
    ...mapActions({
      // getGoods:"getGoods",
      getmemberName:"getmemberName"
    }),
    ...mapMutations({
      changeFlag: "changeFlag",
      changeBoolean: "changeBoolean",
      setCarGoodsNum: "setCarGoodsNum",
    }),
    jumpSmartSourcing(){
      this.$router.push({name:"SmartSourcing"})
    },
    jumpMy() {
      this.$router.push({ name: "MyAccount" });
    },
    jumpMyorder() {
      this.$router.push({ name: "MyOrder" });
    },
    jumpAbout() {
      this.$router.push({ name: "About" });
    },
    jumpLogin() {
        Cookies.remove('memberName');
      this.$router.push({ name: "Login" });
    },
    jumpRegister() {
      this.$router.push({ name: "Register" });
    },
    jumpCart() {

      const { href } = this.$router.resolve({
        path: '/cart',
      });
      window.open(href, '_blank');


      // this.$router.push({ name: "Cart" });
    },
  },
};
</script>
