
  <template>
  <el-row>
    <el-col :span="24">
        <div class="zone">
           <!-- <img :src="imgUrl" alt=""> -->
            <img v-if="titmsg=='新品上市'" :src="newUrl" alt="">
           <img  v-if="titmsg=='非处方药'" :src="otcUrl" alt="">
           <img  v-if="titmsg=='医保用药'" :src="ybUrl" alt="">
           <h3>{{titmsg}}</h3>
           <ul class="title" v-if="arr">
               <li v-for="(item,index) in arr"  :key="index" @click="changeIndex(item,index)">{{ item.name }}</li>
           </ul>
           <div class="moreInfo" @click="jumpProductType">更多</div>
        </div>
    </el-col>
   
  </el-row>
</template>

<style lang="less" scoped>
@import '~style/index.less';

.zone {
    padding: 14px 20px;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    img {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    h3 {
        float: left;
        margin-left: 10px;
        margin-right: 12px;
        color: #333;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .title {
        li {
            float: left;
            cursor: pointer;
            &:hover{
                color: @theme;
            }
            .fonts();
            color: #999;
            padding-left: 27px;
        }
    }
    .moreInfo {
        position: absolute;
        right:20px;
         cursor: pointer;
            &:hover{
                color: @theme;
            }
         .fonts();
         color: #666;
    }
}
    
      
</style>

<script>
import Qs from "qs";
// import Title from 'assets/logo.png';
import newUrl from 'assets/new.png';
import otcUrl from 'assets/OTC.png';
import ybUrl from 'assets/yibao.png';
export default {
    name:"AdHeader",
    data(){
        return {
            // arr:["全国甲类","全国乙类","部分城市"],
            // imgUrl:Title
            // 新品  医保  otc
            newUrl:newUrl,
            otcUrl:otcUrl,
            ybUrl:ybUrl,
        }
    },
    props:{
        titmsg:{
              type:String
        },
        arr:{
            type:Array
        }
    },
     created() {
    },
    methods:{
        changeIndex(item,index){
            if(this.titmsg=="医保用药"){
                 sessionStorage.setItem("parmesData",Qs.stringify(item.type))
                 this.$router.push({name:"ProductType",query:{type:10}})
            }else if(this.titmsg=="非处方药"){
                  this.$router.push({name:"ProductType",query:{type:4}})
                  sessionStorage.setItem("parmesData",Qs.stringify(item.type))
            }
            this.currentIndex = index
        },
        jumpProductType(){
           if(this.titmsg=="医保用药"){
                 this.$router.push({name:"ProductType",query:{type:10}})
           }else if(this.titmsg=="非处方药"){
                this.$router.push({name:"ProductType",query:{type:4}})
           }else if(this.titmsg=="新品上市"){
                this.$router.push({name:"ProductType",query:{type:9}})
           }
        }
    },
    components:{
        
    }
};
</script>
  
