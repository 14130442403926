<template>
  <ul class="elevator_list" v-show="!flag">
    <li class="searchHigh" @click.stop='SearchShowFun'>
        <div class="iconBox"><i class="el-icon-search"></i></div>
        <div class="link">搜索</div>
    </li>
    <li   v-popover:customer_service>
      <!--  width="150" -->
      <el-popover
        trigger="hover"
        placement="left"
        ref="customer_service"
      >
        <div>
          <div class="customerBox">
            <div class="left_box">
              <el-avatar :size="30" icon="el-icon-phone-outline"></el-avatar>
            </div>
            <div class="right_box">
              <p>专属客服:<strong>{{ info.customName }}</strong></p>
              <p>{{ info.customPhone }}</p>
            </div>
          </div>

          <div class="customerBox">
            <div class="left_box">
              <el-avatar :size="30" icon="el-icon-phone-outline"></el-avatar>
            </div>
            <div class="right_box">
              <p>平台客服热线</p>
              <p>4001008189</p>
            </div>
          </div>
        </div>
      </el-popover>
      <div class="iconBox"><img src="@/assets/kefuIcon.png" alt=""></div>
      <div
        class="link"
        @mouseenter="GetName()"
        >
        联系客服
      </div>
    </li>
    <!-- <li @click="jumpQuickOrder">
      <div class="iconBox"><img src="@/assets/QuickOrderIcon.png" alt=""></div>
      <div class="link" >快速下单</div>
    </li>  -->

    
    <li @click="jumpCoupon">
      <div class="iconBox"> <img src="@/assets/CouponCenterIcon.png" alt=""></div>
      <div class="link" >领券中心</div>
    </li> 
    <li  @click="jumpMyOrder">
      <div class="iconBox"> <img src="@/assets/MyOrderIcon1.png" alt=""></div>
      <div class="link">我的订单</div>
    </li>

    <!-- 我的订阅 -->
    <!-- <li  @click="jumpMyOrder">
      <div class="iconBox"> <img src="@/assets/dingyueIcon.png" alt=""></div>
      <div class="link">我的订阅</div>
    </li> -->

    <li @click="jumpMyBalance">
      <div class="iconBox"> <img src="@/assets/MyBalanceIcon.png" alt=""></div>
      <div class="link">我的余额</div>
    </li>

    <!-- 个人中心 -->
    <!-- <li @click="jumpMyBalance">
      <div class="iconBox"> <img src="@/assets/MyCenter.png" alt=""></div>
      <div class="link">个人中心</div>
    </li> -->
    <li @click="jumpSmartSourcing">
      <div class="iconBox"> <img src="@/assets/buyerIcon.png" alt=""></div>
      <div class="link">智能采购</div>
    </li>




    <!-- <li class="QRcode">
      <img src="@/assets/3AApp_down.png" alt="">
      <a>扫码二维码下载</a>
    </li> -->
    <li @click="backtopFun">
      <div class="iconBox"> <img src="@/assets/TopBackIcon.png" alt=""></div>
      <div  class="link">回到顶部</div>
      <!-- <el-backtop >
      </el-backtop> -->
    </li>
  </ul>
</template>
<style scoped lang="less">
@import "~style/index.less";

.customerBox:last-child {
  margin-bottom: 0;
}
.customerBox {
  height: 36px;
  margin-bottom: 5px;
  .left_box {
    float: left;
    padding-right:10px;
    .el-avatar {
      background: #F43C38 ;
    }
  }
  .right_box {
    float: left;
    p {
      line-height: 17px;
      font-size:12px;
      color: #333333;
      &:nth-child(2){
        font-weight: bold;
      }
    }
  }
}
.elevator_list {
  // padding: 30px 0;
  // height: calc(100% - 60px);
  padding:50px 0;
  height: calc(100% - 100px);
  background:#333333FF;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index:1000;

  border-left: 1px solid #e3dcdc;
    display: -webkit-flex; /* Safari */
    -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* 行与行之间留有均匀间隔。 */
   // top: 50% !important;
  // left: 50% !important;
  // margin-left: 615px;
  // margin-bottom: 56px;
  // margin-top: -90px;
  li {
    width:60px;
    background: #333333FF;
    color: #666666;
    text-align: center;
    font-size: 13px;
    // background-color:coral;
    padding-bottom: 10px;
    // border: 1px solid #F2F2F2;
    .iconBox{
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      display: block;
      margin:10px auto;
      // border: 1px solid #F2F2F2;
      img{
        width: 30px;
      }
      i{
        font-weight: bold;
        color: #fff;
        font-size: 20px;
      }
    }
    
    .link {
      color: #fff;
      width:30px;
      padding:0 15px;
      text-align: center;
      font-size: 13px;
      display: block;
    }
  }
  // .QRcode{
  //   width: 94px;
  //   margin-top: 10px;
  //   padding-bottom: 10px;
  //   img{
  //     padding-top: 10px;
  //     width:74px;
  //     height:74px;
  //   }
  //   a{
  //     width: 100%;
  //     display: inline-block;
  //     font-size: 12px;
  //     color: #333;
  //     line-height: 14px;
  //     font-weight: 600;
  //   }
  // }
  li:hover {
    background: #F43C38;
    color: #fff;
    a {
      color: #fff;
    }
  }
  .link:hover {
    background: #F43C38;
    color: #fff;
    font-weight: bold;
    a {
      color: #fff;
    }
  }
}
// .el-backtop {
//   padding-bottom: 5px;
//   border-radius: 0%;
//   color: #F43C38 ;
//   right: 0 !important;
//   bottom: 0 !important;
//   width: 30px;
//     padding: 0 15px
//   // left: 50% !important;
//   // margin-left: 615px;
//   // top: 50% !important;
//   //  margin-top:160px!important;
// }
// .el-backtop:hover {
//   background: #F43C38;
//   color: #fff;
// }

// @media screen and (max-width: 1280px) {
//   // .el-backtop {
//   //   margin-left: 585px;
//   // }
//   // .elevator_list {
//   //   margin-left: 585px;
//   // }
// }
</style>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      info: {},
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  computed: {
    //计算属性可实时更新购物车数量
    ...mapState({
      flag: (state) => state.flag,
    }),
  },
  created() {
    // if(!this.flag){
    //   this.$set(this.info, "customName",localStorage.getItem("customName"));
    //   this.$set(this.info, "customPhone",localStorage.getItem("customPhone"));
    // }
  },
  methods: {

    SearchShowFun(){
      this.$emit('clickHeader', true)
    },
     // 快速下单
    //  jumpQuickOrder(){
    //     this.$router.push({ name: "QuickOrder" });
    // },
    // 领券中心
    jumpCoupon(){
       this.$router.push({ name: "GetCoupon" });
    },
     jumpGetCoupon(){
       this.$router.push({ name: "Coupon" });
    },
    GetName() {
      this.$set(this.info, "customName", localStorage.getItem("customName"));
      this.$set(this.info, "customPhone", localStorage.getItem("customPhone"));
    },
    jumpMyBalance() {
      // this.$router.push({ name: "Coupon" });
       this.$router.push({ name: "Balance" });
    },
    jumpMyOrder() {
      this.$router.push({ name: "MyOrder" });
    },
    jumpSmartSourcing() {
      this.$router.push({ name: "SmartSourcing" });
    },

   

    backtopFun(){
      // 设置滚动行为改为平滑的滚动
      window.scrollTo({
          top:0,
          behavior: "smooth"
      });
    },
    ...mapMutations({
      changeFlag: "changeFlag",
    }),
  },
};
</script>
